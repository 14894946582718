<template>
    <div style="background: #fff7d9 !important;">
        <!-- <img src="/build/assets/img/brand/khit_thit.png" alt="" /> -->
        <div class="px-3 mx-auto p-0 d-flex main-image-wrapper mt-2 pt-1" v-if="!threedData.isLive">
            <b-carousel
                id="carousel-1"
                :interval="3000"
                controls
                fade
                style="text-shadow: 1px 1px 2px #333; width: 100%; height: 100%;"
            >
                <b-carousel-slide
                    img-src="/build/assets/img/theme/main1.png"
                ></b-carousel-slide>

                <b-carousel-slide
                    img-src="/build/assets/img/theme/main2.png"
                ></b-carousel-slide>
                <b-carousel-slide
                    img-src="/build/assets/img/theme/main3.png"
                ></b-carousel-slide>
                <b-carousel-slide
                    img-src="/build/assets/img/theme/main4.png"
                ></b-carousel-slide>
            </b-carousel>
        </div>
        <div
            class="m-auto col-12 pt-4 video-container"
            v-if="threedData.embedded_link && threedData.isLive"
            v-html="threedData.embedded_link"
            style=" width: 100%; height: 100%;"
        ></div>

        <div class="account-container-wrapper py-3 my-3 mx-3">
            <div class="top-wrapper px-2">
                <div class="top-left">
                    <i class="fas fa-wallet my-auto mr-3"></i>
                    <div class="my-auto">
                        <b>
                            {{
                                $store.state.language === "en"
                                    ? "Main Wallet :"
                                    : "ပိုက်ဆံအိတ် :"
                            }}
                        </b>
                    </div>
                </div>
                <div class="top-right">
                    <div class="my-auto">
                        <b>{{
                            Number($store.state.amount).toLocaleString()
                        }}</b>
                        {{ $store.state.language === "en" ? "MMK" : "ကျပ်" }}
                    </div>
                </div>
            </div>
            <!-- <hr /> -->
            <!-- <div class="bottom-wrapper px-3">
                <div class="bottom-left">
                    <i class="fas fa-user my-auto mr-3"></i>
                    <div class="my-auto">
                        {{
                            $store.state.language === "en"
                                ? "Phone Number :"
                                : "ဖုန်းနံပါတ် :"
                        }}
                    </div>
                </div>
                <div class="bottom-right">
                    <div class="my-auto">{{ $store.state.authUser.phone }}</div>
                </div>
            </div> -->
        </div>

        <NoticeBar
            class="col-lg-12 col-12 mx-auto notice-bar"
            scrollable
            :text="websiteInfo[0]?.noticeText"
        />

        <div class="justify-content-center cards-container-mobile">
            <div
                class="col-xl-6 col-6 home-cards mb-1"
                @click="redirectRoute('user/slots', {provider: 'Pragmatic'})"
                v-if="$store.state.authUser.parent_info.is_slot_enabled"
            >
                <div class="d-flex justify-content-start align-items-start flex-column position-relative mb-3">
                    <img
                        src="/build/assets/img/theme/pp.png"
                        alt=""
                        style="width: 100%; height: 120px; border-radius: 10px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
                    />
                </div>
            </div>

            <div
                class="col-xl-6 col-6 home-cards mb-1"
                @click="redirectRoute('user/slots', {provider: 'Jili'})"
                v-if="$store.state.authUser.parent_info.is_slot_enabled"
            >
                <div class="d-flex justify-content-end align-items-end flex-column position-relative mb-3">
                    <img
                        src="/build/assets/img/theme/jili.png"
                        alt=""
                        style="width: 100%; height: 120px; border-radius: 10px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
                    />
                </div>
            </div>

            <div
                class="col-xl-6 col-6 home-cards mb-1"
                @click="redirectRoute('user/skm-rooms')"
            >
                <div class="d-flex justify-content-end align-items-end flex-column position-relative mb-3">
                    <img
                        src="/build/assets/img/theme/skm.jpg"
                        alt=""
                        style="width: 100%; height: 120px; border-radius: 10px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
                    />
                </div>
            </div> 

            <div
                class="col-xl-6 col-6 home-cards mb-1"
                @click="redirectRoute('user/bugyee-rooms')"
                 v-if="$store.state.authUser.parent_info.is_card_enabled"
            >
                <div class="d-flex justify-content-start align-items-start flex-column position-relative mb-3">
                    <img
                        src="/build/assets/img/theme/bugyee.jpg"
                        alt=""
                        style="width: 100%; height: 120px; border-radius: 10px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
                    />
                </div>
            </div>  

            <div
                class="col-xl-6 col-6 home-cards mb-1"
                @click="redirectRoute('body-fb')"
                 v-if="$store.state.authUser.parent_info.is_football_enabled"
            >
                <div class="d-flex justify-content-start align-items-start flex-column position-relative mb-3">
                    <img
                        src="/build/assets/img/theme/body.jpg"
                        alt=""
                        style="width: 100%; height: 120px; border-radius: 10px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
                    />
                </div>
            </div>

            <div
                class="col-xl-6 col-6 home-cards mb-1"
                @click="redirectRoute('maung-fb')"
                 v-if="$store.state.authUser.parent_info.is_football_enabled"
            >
                <div class="d-flex justify-content-end align-items-end flex-column position-relative mb-3">
                    <img
                        src="/build/assets/img/theme/maung.jpg"
                        alt=""
                        style="width: 100%; height: 120px; border-radius: 10px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
                    />
                </div>
            </div>
            

            <div
                class="col-xl-6 col-6 home-cards mb-1"
                @click="redirectRoute('user/2d/home')"
                 v-if="$store.state.authUser.parent_info.is_twod_enabled"
            >
                <div class="d-flex justify-content-end align-items-end flex-column position-relative mb-3">
                    <img
                        src="/build/assets/img/theme/2dNew.png"
                        alt=""
                        style="width: 100%; height: 120px; border-radius: 10px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
                    />
                </div>
            </div>
            <div
                class="col-xl-6 col-6 home-cards mb-1"
                @click="redirectRoute('user/3d/home')"
                 v-if="$store.state.authUser.parent_info.is_twod_enabled"
            >
                <div class="d-flex justify-content-start align-items-start flex-column position-relative mb-3">
                    <img
                        src="/build/assets/img/theme/3dNew.png"
                        alt=""
                        style="width: 100%; height: 120px; border-radius: 10px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
                    />
                </div>
            </div>
            

             <div
                class="col-xl-6 col-6 home-cards"
                @click="fetchBetGame('horse', 'yoeyar-horse')"
                v-if="$store.state.authUser.parent_info.is_card_enabled"
            >
                <div class="d-flex justify-content-end align-items-end flex-column position-relative mb-3">
                    <img
                        src="/build/assets/img/theme/horse.jpg"
                        alt=""
                        style="width: 100%; height: 120px; border-radius: 10px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
                    />
                </div>
            </div> 

            <!-- <div
                class="col-xl-6 col-6 home-cards"
                @click="fetchBetGame('tiger-dragon', 'yoeyar-dragon-tiger')"
                v-if="$store.state.authUser.parent_info.is_card_enabled"
            >
                <div class="d-flex justify-content-start align-items-start flex-column position-relative mb-3">
                    <img
                        src="/build/assets/img/theme/tigeranddragon.jpg"
                        alt=""
                        style="width: 100%; height: 120px; border-radius: 10px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
                    />
                </div>
            </div>  -->

            <div
                class="col-xl-6 col-6 home-cards"
                @click="redirectRoute('user/fishing', {provider: 'Jili'})"
            >
                <div class="d-flex justify-content-center align-items-center flex-column position-relative mb-3">
                    <img
                        src="/build/assets/img/theme/fishing.png"
                        alt=""
                        style="width: 100%; height: 120px; border-radius: 10px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
                    />
                </div>
            </div>  

            <div
                class="col-xl-6 col-6 home-cards"
                @click="fetchBetGame('abcd', 'yoeyar-abcd')"
                v-if="$store.state.authUser.parent_info.is_card_enabled"
            >
                <div class="d-flex justify-content-end align-items-end flex-column position-relative mb-3">
                    <img
                        src="/build/assets/img/theme/abcd.jpg"
                        alt=""
                        style="width: 100%; height: 120px; border-radius: 10px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
                    />
                </div>
            </div>  
 
            <div
                class="col-xl-6 col-6 home-cards"
                @click="redirectRoute('betslip-history')"
            >
                <div class="d-flex justify-content-start align-items-start flex-column position-relative mb-3">
                    <img
                        src="/build/assets/img/theme/voucher.jpg"
                        alt=""
                        style="width: 100%; height: 120px; border-radius: 10px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
                    />
                </div>
            </div>
            <!-- <div
                class="col-xl-6 col-6 home-cards pl-2"
                style="padding-bottom: 9rem"
                @click="redirectRoute('history')"
            >
                <div class="d-flex justify-content-center align-items-center flex-column position-relative mb-3">
                    <img
                        src="/build/assets/img/theme/fishing.png"
                        alt=""
                        style="width: 100%; height: 120px; border-radius: 10px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
                    />
                </div>
            </div>   -->
            <div
                class="col-xl-6 col-6 home-cards mb-1"
                style="padding-bottom: 9rem"
                @click="redirectRoute('history')"
            >
                <div class="d-flex justify-content-center align-items-center flex-column position-relative mb-3">
                    <img
                        src="/build/assets/img/theme/transaction.jpg"
                        alt=""
                        style="width: 100%; height: 120px; border-radius: 10px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
                    />
                </div>
            </div>  
        </div>
        <div class="col-4">
            <div
                class="modal fade"
                id="nickname"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modal-form"
            >
                <div
                    class="modal-dialog modal- modal-dialog-centered"
                    role="document"
                >
                    <div class="modal-content">
                        <div class="modal-body my-5 mx-3 text-center">
                            <div v-if="isPortrait">
                                <h3 class="text-danger">
                                    <i class="fas fa-sync fa-spin text-dark"></i>&nbsp;
                                    {{
                                        $store.state.language === "en"
                                            ? "Please rotate your device to landscape mode for the best experience."
                                            : "ကျေးဇူးပြု၍ သင့်၏ mobile phone အား အလျားလိုက်ဖြစ်အောင် လှည့်ပေးပါ။"
                                    }}
                                    &nbsp;<i class="fas fa-sync fa-spin text-dark"></i>
                                </h3>
                            </div>
                            <span class="text-dark mb-3">
                                <b>Please enter nickname : </b> <br><br>
                            </span>
                            <input
                                class="form-control"
                                type="text"
                                v-model="nickname"
                            />
                            <br><span class="text-dark text-sm">
                                <button class="btn btn-success" :class="isPortrait ? 'disabled' : ''" @click="isPortrait ? '' : fetchBetGame()">
                                {{
                                    $store.state.language === "en"
                                        ? "Okay"
                                        : "ဟုတ်ပြီ။"
                                }}
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <van-overlay :show="isLoading" @click="isLoading = false">
            <div class="wrapper" @click.stop>
              <van-loading type="spinner" color="#ffffff" class="mx-auto"/>
            </div>
        </van-overlay>

    </div>
</template>
<script>
import { NoticeBar, Dialog } from 'vant';

export default {
  components: { NoticeBar },
  data() {
    return {
     test: "<iframe src='https://xem.bdhub.xyz/v7/?link=https://apisportvb90xltcbk.nsnd.live/live/sd-1l4rjnh4y3yvm7v/playlist.m3u8' width='100%' height='400px' allowtransparency='yes' allow='autoplay' frameborder='0' marginheight='0' marginwidth='0' allowfullscreen='' scrolling='auto'></iframe>",
        isPortrait: window.orientation === 0 || window.orientation === 180,
      twoDdata: {},
      twoDHistories: {},
      threeDHistories: {},
      threedData: {
        embedded_link: `<iframe src="https://xem.bdhub.xyz/v7/?link=https%3A%2F%2Fapisportvb90xltcbk.nsnd.live%2Flive%2Fsd-318q66h6lkwoqo9%2Fplaylist.m3u8" width="100%" height="100%" allowtransparency="yes" allow="autoplay" frameborder="0" marginheight="0" marginwidth="0" allowfullscreen="" scrolling="auto"></iframe>`
      },
      websiteInfo: {},
      isLoading: false,
      nickname: null,
      gameName: 'abcd',
      gameLink: 'yoeyar-abcd'
    };
  },
  methods: {
    async fetchBetGame(game = null, link = null) {
      try {
        // if(game === 'tiger-dragon'){
        //     alert('This game is under Maintenance.');
        //     return false;
        // }

        this.isLoading = true;
        
        if(game){
            this.gameName = game
            this.gameLink = link
        }
            const res = await axios.get(`/${this.gameName}-data`, {
            params: {
                    id : this.$store.state.authUser.name,
                    balance : this.$store.state.authUser.amount,
                    info : {
                            nickname : this.$store.state.authUser.user_name,
                            profile : 3
                        }
                    }
            });
            window.location.assign(`https://${link}.vercel.app/?id=${this.$store.state.authUser.name}&passcode=${res.data.passcode}&exit=https%3A%2F%2F${window.location.host}/home`)
            this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },

    async redirectRoute(path, query = null) {
      try {
        if (path === 'tip') {
          const text = this.$store.state.language === 'en'
            ? 'This feature is not support yet.'
            : 'ယခုအချိန်တွင် အသုံးပြုလို့မရနိုင်သေးပါ။';
          Dialog.alert({
            message: text,
            confirmButtonText:
                    this.$store.state.language === 'en'
                      ? 'Okay'
                      : 'Okay',
          }).then(() => {
          // on close
          });
        } else if(query) {
          this.$router.push({ path, query });
        } else {
          this.$router.push(path);
        }
      } catch (error) {
        console.log(error);
      }
    },
    threedDrawDateFormat(date) {
      return moment(date).format('ll');
    },
    async fetchTwoDdata() {
      try {
        const res = await axios.get('/2d/data');
        this.isLive = res.data.data[0].isLive;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchWebsiteData() {
      try {
        const res = await axios.get('/website-infos');
        this.websiteInfo = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    
    async fetchThreedDdata() {
      try {
        const res = await axios.get('/3d/data');
        this.threedData = res.data.data[0];
      } catch (error) {
        console.log(error);
      }
    },
    async fetchTwoThreeDHistories() {
      try {
        const twoDres = await axios.get('/twod_history');
        const threeDres = await axios.get('/threed_history');
        this.twoDHistories = twoDres.data.data;
        this.threeDHistories = threeDres.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    handleOrientationChange() {
      this.isPortrait = window.orientation === 0 || window.orientation === 180;
    },
  },
  beforeDestroy() {
    window.removeEventListener('orientationchange', this.handleOrientationChange);
  },
  async mounted() {
    window.addEventListener('orientationchange', this.handleOrientationChange);
    this.fetchWebsiteData();
    // this.fetchTwoDdata();
    this.fetchThreedDdata();
    // this.fetchTwoThreeDHistories();
  },
};
</script>
<style scoped>
.card {
    cursor: pointer;
}
.main-image-wrapper img {
    width: 100%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
.video-container {
    height: 500px;
    width: 100%;
}
.notice-bar {
    margin-top: -40px;
    z-index: 1;
    /* color: white; */
    font-size: 20px;
    /* background: rgba(0, 0, 0, 0.5); */
    color: yellow !important;
    background: rgba(255, 242, 0, 0.7);
    /* border-top: 1px solid yellow;
    border-bottom: 1px solid yellow; */
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
.twod-threed-cards {
    height: 200px;
}
.live-number {
    font-weight: bold;
    font-size: 25px;
}
.live-result {
    font-weight: bolder;
    font-size: 60px;
    animation-name: live-number-animation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
.not-live-text {
    font-weight: bold;
    font-size: 24px;
}
.cards-container {
    display: flex;
    flex-wrap: wrap;
}
.card-body {
    padding: 0px !important;
}
.cards-container-mobile {
    display: none;
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

@keyframes live-number-animation {
    from {
        color: white;
    }
    to {
        color: red;
    }
}

@media (max-width: 768px) {
    .middle-container {
        padding-top: 0.1rem !important;
    }
    hr {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .video-container {
        height: 300px;
        width: 100%;
    }
    .main-wrapper {
        padding: 7px !important;
    }
    .notice-bar {
        margin-top: 0px;
        height: 35px;
        z-index: 1;
        font-size: 12px;
        color: white;
        background: #65211f;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    }
    .live-number {
        font-weight: bold;
        font-size: 20px;
    }
    .live-result {
        font-weight: bolder;
        font-size: 37px;
    }

    .cards-container {
        display: none;
    }
    .cards-container-mobile {
        padding: 0px !important;
        margin-top: 15px !important;
        display: flex;
        flex-wrap: wrap;
    }
    .home-cards {
        padding-left: 10px;
        padding-right: 10px;
    }
    .card-body {
        padding: 0px !important;
    }
    .not-live-text {
        font-size: 18px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .account-container-wrapper {
        width: auto;
        height: auto;
        border-radius: 3px;
        padding: 4px;
        /* margin: 10px; */
        color: rgb(29,2,0);
        background: #F5C065;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    }
    .top-wrapper,
    .bottom-wrapper {
        padding: 10px;
        width: 100%;
        display: flex;
    }
    .top-right,
    .bottom-right {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: end;
    }
    .top-left,
    .bottom-left {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: start;
    }
}
</style>
