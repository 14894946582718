import { render, staticRenderFns } from "./Slot.vue?vue&type=template&id=1a0d8568&scoped=true&"
import script from "./Slot.vue?vue&type=script&lang=js&"
export * from "./Slot.vue?vue&type=script&lang=js&"
import style0 from "./Slot.vue?vue&type=style&index=0&id=1a0d8568&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a0d8568",
  null
  
)

export default component.exports